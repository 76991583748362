var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-container"},[_c('div',[(_vm.view_total_hours)?[_c('b-form-input',{attrs:{"size":"sm"},on:{"update":(value) => {
            if (parseFloat(value) < 0 || value == null || value == '') {
              _vm.epm_time_allocation.hours = 0;
              _vm.patchEPMTimeAllocation();
            } else {
              _vm.epm_time_allocation.hours = value;
              _vm.patchEPMTimeAllocation();
            }
          }},model:{value:(_vm.epm_time_allocation.hours),callback:function ($$v) {_vm.$set(_vm.epm_time_allocation, "hours", $$v)},expression:"epm_time_allocation.hours"}})]:(!_vm.view_total_hours)?[_c('b-form-input',{attrs:{"size":"sm"},on:{"update":(value) => {
            if (parseFloat(value) < 0 || value == null || value == '') {
              _vm.epm_time_allocation.weekly_hour = 0;
              _vm.epm_time_allocation.hours = 0;
              _vm.patchEPMTimeAllocation();
            } else {
              _vm.epm_time_allocation.weekly_hour = value;
              _vm.patchEPMTimeAllocation();
            }
          }},model:{value:(_vm.epm_time_allocation.weekly_hour),callback:function ($$v) {_vm.$set(_vm.epm_time_allocation, "weekly_hour", $$v)},expression:"epm_time_allocation.weekly_hour"}})]:_vm._e()],2),_c('b-form-checkbox',{staticClass:"ml-1",attrs:{"size":"md"},on:{"input":function($event){return _vm.patchEPMTimeAllocation()}},model:{value:(_vm.epm_time_allocation.counts_towards_credits),callback:function ($$v) {_vm.$set(_vm.epm_time_allocation, "counts_towards_credits", $$v)},expression:"epm_time_allocation.counts_towards_credits"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }